var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "newsletterBox", attrs: { id: "iscrizione-newsletter" } },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [_c("Spinner", { attrs: { msg: "Solo un istante..." } })],
            1
          )
        : _vm.msg !== ""
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("b-alert", { attrs: { show: "", variant: "success" } }, [
                _vm._v("\n            " + _vm._s(_vm.msg) + "\n        "),
              ]),
            ],
            1
          )
        : _c(
            "form",
            {
              ref: "formnewsletter",
              attrs: { target: "_blank", action: _vm.newsletter.endpoint },
            },
            [
              _c("p", { staticClass: "newsletterTitle" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.newsletter.title) },
                }),
                _c("br"),
                _c("span", {
                  staticClass: "small",
                  domProps: { innerHTML: _vm._s(_vm.newsletter.payoff) },
                }),
              ]),
              _c(
                "div",
                { staticClass: "customCheckboxBlock" },
                [
                  _c("b-form-checkbox", {
                    ref: "newsletterCheckboxPrivacy",
                    attrs: {
                      required: "",
                      type: "checkbox",
                      name: "newsletterCheckboxPrivacy",
                      id: "newsletterCheckboxPrivacy",
                    },
                    model: {
                      value: _vm.newsletterCheckboxPrivacy,
                      callback: function ($$v) {
                        _vm.newsletterCheckboxPrivacy = $$v
                      },
                      expression: "newsletterCheckboxPrivacy",
                    },
                  }),
                  _c("input", {
                    attrs: { type: "hidden", name: "privacy" },
                    domProps: { value: _vm.newsletterCheckboxPrivacy },
                  }),
                  _c("label", { attrs: { for: "newsletterCheckboxPrivacy" } }, [
                    _vm._v(
                      "\n              Inviando questo modulo, confermo di aver letto e accettato l'"
                    ),
                    _c("span", { staticClass: "secondary" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.privacyPolicyUrl,
                            target: "_blank",
                          },
                        },
                        [_vm._v("Informativa sulla Privacy")]
                      ),
                    ]),
                    _vm._v(".\n            "),
                  ]),
                ],
                1
              ),
              _c("br"),
              _c("div", { staticClass: "d-flex" }, [
                _c(
                  "div",
                  { staticClass: "customInputBlock emailBlock" },
                  [
                    _c("b-form-input", {
                      ref: "newsletterEmail",
                      attrs: {
                        required: true,
                        type: "email",
                        placeholder: "Inserisci la tua email",
                      },
                      model: {
                        value: _vm.newsletterEmail,
                        callback: function ($$v) {
                          _vm.newsletterEmail = $$v
                        },
                        expression: "newsletterEmail",
                      },
                    }),
                    _c("input", {
                      attrs: { type: "hidden", name: "email" },
                      domProps: { value: _vm.newsletterEmail },
                    }),
                    _c("span", { staticClass: "formError" }),
                    _c("span", {
                      staticClass: "formClear",
                      on: {
                        click: function ($event) {
                          return _vm.clearField("newsletterEmail")
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "loginBlockLogin" }, [
                  _c(
                    "div",
                    { staticClass: "loginButton" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { disabled: !_vm.validateFields() },
                          on: { click: _vm.submit },
                        },
                        [_vm._v("ISCRIVITI")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("input", {
                attrs: { type: "hidden", id: "apgroup", name: "apgroup" },
                domProps: { value: _vm.newsletter.apgroup },
              }),
              _c("input", {
                attrs: { type: "hidden", name: "list" },
                domProps: { value: _vm.newsletter.list },
              }),
            ]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }