<template>
    <div id="iscrizione-newsletter" class="newsletterBox">

        <div v-if="loading" class="text-center">
            <Spinner :msg="'Solo un istante...'" />
        </div>

        <div v-else-if="msg !== ''" class="text-center">
            <b-alert show variant="success">
                {{msg}}
            </b-alert>
        </div>

        <form v-else ref="formnewsletter" target="_blank" :action="newsletter.endpoint">
            <p class="newsletterTitle">
                <span v-html="newsletter.title"></span>
                <br>
                <span class="small" v-html="newsletter.payoff"></span>
            </p>

            <div class="customCheckboxBlock">
                <b-form-checkbox v-model="newsletterCheckboxPrivacy" ref="newsletterCheckboxPrivacy" required type="checkbox" name="newsletterCheckboxPrivacy" id="newsletterCheckboxPrivacy" />
                <input type="hidden" name="privacy" :value="newsletterCheckboxPrivacy" />
                <label for="newsletterCheckboxPrivacy">
                  Inviando questo modulo, confermo di aver letto e accettato l'<span class="secondary"><a :href="privacyPolicyUrl" target="_blank">Informativa sulla Privacy</a></span>.
                </label>
            </div>
            
            <!--
            <div class="customCheckboxBlock">
                <b-form-checkbox v-model="newsletterCheckboxTerms" ref="newsletterCheckboxTerms" required type="checkbox" name="newsletterCheckboxTerms" id="newsletterCheckboxTerms" />
                <label for="newsletterCheckboxTerms">
                    <strong>Do il consenso</strong> a ricevere per email news, anche personalizzate, riguardo offerte speciali e idee di viaggio da Salabam srl.
                    Potrai disiscriverti in ogni momento usando il link presente in ogni nostra email.
                    <router-link :to="{name: 'terms'}" target="_blank" class="nounderline">
                        <span class="secondary">Termini e condizioni</span>
                    </router-link>
                </label>
            </div>
            -->

            <br>

            <div class="d-flex">
                <div class="customInputBlock emailBlock">
                    <b-form-input ref="newsletterEmail" :required="true" v-model="newsletterEmail" type="email" placeholder="Inserisci la tua email" />
                    <input type="hidden" name="email" :value="newsletterEmail" />
                    <span class="formError"></span>
                    <span class="formClear" @click="clearField('newsletterEmail')"></span>
                </div>

                <div class="loginBlockLogin">
                    <div class="loginButton">
                        <b-button :disabled="!validateFields()" @click="submit">ISCRIVITI</b-button>
                    </div>
                </div>
            </div>

          <input type="hidden" id="apgroup" name="apgroup" :value="newsletter.apgroup">
          <input type="hidden" name="list" :value="newsletter.list">

        </form>

    </div>
</template>

<script>
import Vue from 'vue'
import Spinner from './../../atoms/spinner'
export default {
    name: 'newsletter',
    components:
    {
        Spinner,
    },
    props:{
      newsletter: {
        type: Object,
        value: {},
      },
    },
    data()
    {
        return {
            loading: false,
            newsletterEmail: '',
            //newsletterCheckboxTerms: false,
            newsletterCheckboxPrivacy: false,
            msg: '',
            privacyPolicyUrl: Vue.prototype.$config.site.urlPrivacy,
        } 
    },
    methods:
    {
        validateFields()
        {
            if(Object.keys(this.$refs).length == 0) return false
            return this.mixinValidateFields([
                {
                    ref: this.$refs.privacy,
                    type: 'checkbox',
                    value: this.newsletterCheckboxPrivacy,
                },
                /*
                {
                    ref: this.$refs.terms,
                    type: 'checkbox',
                    value: this.newsletterCheckboxTerms,
                },*/
                {
                    ref: this.$refs.newsletterEmail,
                    type: 'email',
                    value: this.newsletterEmail,
                    optional: true,
                },
            ])
        }, 
        clearField(ref)
        {
            this[ref] = ''
            this.$refs[ref].focus()
            this.validateFields()
        },
        submit(){
          this.loading = true
          this.$refs.formnewsletter.submit()
          this.msg = 'Grazie per aver sottoscritto la nostra newsletter'
          this.loading = false
        },
    }  
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .newsletterBox
    {
        display: flex;
        justify-content: center;

        label
        {
            line-height: 140%;
            font-size: 12px;
        }

        .newsletterTitle
        {
            font-size: 20px;
            color:$black;
            font-weight:bold;
            .small
            {
                font-size:16px;
                font-weight:bold;
            }
        }
        .emailBlock
        {
            flex:1;
            min-width: auto;
            max-width: 360px;
            margin-right: 1.5rem;
        }
    }

</style>